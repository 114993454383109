import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslocoService } from "@ngneat/transloco";
import Swal from "sweetalert2";
import { contactNo, nameValidator, otp } from "./regex";
import { RegisterpageService } from "./registerpage.service";

@Component({
  selector: "app-registerpage",
  templateUrl: "registerpage.component.html",
  styleUrls: ["registerpage.component.scss"]
})
export class RegisterpageComponent implements OnInit, OnDestroy {
  isCollapsed = true;
  focusAgentOutletName;
  focusFirstName;
  focusLastName;
  focusCity;
  focusMobileNo;
  focusOtp;

  otp: boolean = false;
  resendOtpLoading: boolean = false;
  loading: boolean = false;
  registrationForm : FormGroup;
  selectedLanguage = "en";

  cityList = [
    "Colombo 01",
    "Colombo 02",
    "Colombo 03",
    "Colombo 04",
    "Colombo 05",
    "Colombo 06",
    "Colombo 07",
    "Colombo 08",
    "Colombo 09",
    "Colombo 10",
    "Colombo 11",
    "Colombo 12",
    "Colombo 13",
    "Colombo 14",
    "Colombo 15",     
    "Akarawita",
    "Angamuwa",
    "Avissawella",
    "Batawala",
    "Battaramulla",
    "Batugampola",
    "Bope",
    "Boralesgamuwa",
    "Borella",
    "Dedigamuwa",
    "Dehiwala",
    "Deltara",
    "Habarakada",
    "Handapangoda",
    "Hanwella",
    "Hewainna",
    "Hiripitya",
    "Hokandara",
    "Homagama",
    "Horagala",
    "Kaduwela",
    "Kahawala",
    "Kalatuwawa",
    "Madapatha",
    "Maharagama",
    "Malabe",
    "Meegoda",
    "Padukka",
    "Pannipitiya",
    "Piliyandala",
    "Pitipana Homagama",
    "Polgasowita",
    "Puwakpitiya",
    "Ranala",
    "Siddamulla",
    "Slave Island",
    "Sri Jayawardenapura",
    "Talawatugoda",
    "Tummodara",
    "Waga",
    "Watareka",
    "Akaragama",
    "Alawala",
    "Ambagaspitiya",
    "Ambepussa",
    "Andiambalama",
    "Attanagalla",
    "Badalgama",
    "Banduragoda",
    "Batuwatta",
    "Bemmulla",
    "Biyagama",
    "Biyagama IPZ",
    "Bokalagama",
    "Bollete",
    "Bopagama",
    "Buthpitiya",
    "Dagonna",
    "Danowita",
    "Debahera",
    "Dekatana",
    "Delgoda",
    "Delwagura",
    "Demalagama",
    "Demanhandiya",
    "Dewalapola",
    "Divulapitiya",
    "Divuldeniya",
    "Dompe",
    "Dunagaha",
    "Ekala",
    "Ellakkala",
    "Essella",
    "Gampaha",
    "Ganemulla",
    "GonawalaWP",
    "Heiyanthuduwa",
    "Henegama",
    "Hinatiyana",
    "Hiswella",
    "Horampella",
    "Hunumulla",
    "Ihala Madampella",
    "Imbulgoda",
    "Ja-Ela",
    "Kadawatha",
    "Kahatowita",
    "Kalagedihena",
    "Kaleliya",
    "Kaluaggala",
    "Kandana",
    "Kapugoda",
    "Loluwagoda",
    "Lunugama",
    "Mabodale",
    "Madelgamuwa",
    "Makewita",
    "Makola",
    "Malwana",
    "Mandawala",
    "Marandagahamula",
    "Mellawagedara",
    "Pallewela",
    "Pamunugama",
    "Pamunuwatta",
    "Pasyala",
    "Peliyagoda",
    "Pepiliyawala",
    "Pethiyagoda",
    "Polpithimukulana",
    "Pugoda",
    "Radawadunna",
    "Radawana",
    "Raddolugama",
    "Ragama",
    "Ruggahawila",
    "Rukmale",
    "Seeduwa",
    "Siyambalape",
    "Talahena",
    "Thimbirigaskatuwa",
    "Tittapattara",
    "Udathuthiripitiya",
    "Udugampola",
    "Uggalboda",
    "Urapola",
    "Uswetakeiyawa",
    "Veyangoda",
    "Walgammulla",
    "Walpita",
    "Walpola (WP)",
    "Wanaluwewa",
    "Wathurugama",
    "Watinapaha",
    "Wattala",
    "Weboda",
    "Yakkala",
    "Agalawatta",
    "Alubomulla",
    "Alutgama",
    "Anguruwatota",
    "Baduraliya",
    "Bandaragama",
    "Bellana",
    "Beruwala",
    "Bolossagama",
    "Bombuwala",
    "Boralugoda",
    "Bulathsinhala",
    "Danawala Thiniyawala",
    "Delmella",
    "Dharga Town",
    "Diwalakada",
    "Dodangoda",
    "Dombagoda",
    "Galpatha",
    "Gamagoda",
    "Gonapola",
    "Govinna",
    "Gurulubadda",
    "Halkandawila",
    "Haltota",
    "Halwala",
    "Halwatura",
    "Hedigalla Colony",
    "Horana",
    "Ittapana",
    "Kalawila Kiranthidiya",
    "Kalutara",
    "Kananwila",
    "Kandanagama",
    "lngiriya",
    "Maggona",
    "Mahagama",
    "Mahakalupahana",
    "Matugama",
    "Meegahatenna",
    "Meegama",
    "Padagoda",
    "Pahalahewessa",
    "Paiyagala",
    "Panadura",
    "Pannila",
    "Paragastota",
    "Paragoda",
    "Paraigama",
    "Pelanda",
    "Pelawatta",
    "Pokunuwita",
    "Polgampola",
    "Poruwedanda",
    "Remunagoda",
    "Tebuwana",
    "Uduwara",
    "Utumgama",
    "Veyangalla",
    "Wadduwa",
    "Walagedara",
    "Walallawita",
    "Waskaduwa",
    "Yagirala",
    "Yatadolawatta",
    "Yatawara Junction",
    "Akurana",
    "Alawatugoda",
    "Aludeniya",
    "Ambagahapelessa",
    "Ambatenna",
    "Ampitiya",
    "Ankumbura",
    "Atabage",
    "Balana",
    "Bambaragahaela",
    "Barawardhana Oya",
    "Batagolladeniya",
    "Batugoda",
    "Batumulla",
    "Bawlana",
    "Bopana",
    "Danture",
    "Dedunupitiya",
    "Dekinda",
    "Deltota",
    "Dolapihilla",
    "Dolosbage",
    "Doluwa",
    "Doragamuwa",
    "Dunuwila",
    "Ekiriya",
    "Elamulla",
    "Etulgama",
    "Galaboda",
    "Galagedara",
    "Galaha",
    "Galhinna",
    "Gallellagama",
    "Gampola",
    "Gelioya",
    "Godamunna",
    "Gomagoda",
    "Gonagantenna",
    "Gonawalapatana",
    "Gunnepana",
    "Gurudeniya",
    "Halloluwa",
    "Handaganawa",
    "Handawalapitiya",
    "Akuramboda",
    "Alwatta",
    "Ambana",
    "Ataragallewa",
    "Bambaragaswewa",
    "Beligamuwa",
    "Dambulla",
    "Dankanda",
    "Devagiriya",
    "Dewahuwa",
    "Dullewa",
    "Dunkolawatta",
    "Dunuwilapitiya",
    "Elkaduwa",
    "Erawula Junction",
    "Etanawala",
    "Galewela",
    "Gammaduwa",
    "Gangala",
    "Agarapathana",
    "Ambagamuwa",
    "Ambatalawa",
    "Ambewela",
    "Bogawantalawa",
    "Bopattalawa",
    "Dagampitiya",
    "Dayagama Bazaar",
    "Dikoya",
    "Doragala",
    "Dunukedeniya",
    "Ginigathena",
    "Gonakele",
    "Haggala",
    "Halgranoya",
    "Hangarapitiya",
    "Handessa",
    "Hanguranketha",
    "Harankahawa",
    "Hasalaka",
    "Hataraliyadda",
    "Hewaheta",
    "Hindagala",
    "Hondiyadeniya",
    "Hunnasgiriya",
    "Jambugahapitiya",
    "Kadugannawa",
    "Kahataliyadda",
    "Kalugala",
    "Kandy",
    "Kapuliyadde",
    "Karandagolla",
    "Leemagahakotuwa",
    "lhala Kobbekaduwa",
    "lllagolla",
    "Lunuketiya Maditta",
    "Madawala Bazaar",
    "Madugalla",
    "Madulkele",
    "Mahadoraliyadda",
    "Mahamedagama",
    "Mailapitiya",
    "Makkanigama",
    "Makuldeniya",
    "Mandaram Nuwara",
    "Mapakanda",
    "Marassana",
    "Marymount Colony",
    "Maturata",
    "Mawatura",
    "Medamahanuwara",
    "MedawalaHarispattuwa",
    "Meetalawa",
    "Megoda Kalugamuwa",
    "Menikdiwela",
    "Menikhinna",
    "Pallebowala",
    "Pallekotuwa",
    "Panvila",
    "Panwilatenna",
    "Handungamuwa",
    "Hattota Amuna",
    "Imbulgolla",
    "Inamaluwa",
    "Kaikawala",
    "Kalundawa",
    "Kandalama",
    "Karagahinna",
    "Laggala Pallegama",
    "Leliambe",
    "Lenadora",
    "lllukkumbura",
    "Madawala",
    "Madipola",
    "Mahawela",
    "Mananwatta",
    "Maraka",
    "Matale",
    "Melipitiya",
    "Hapugastalawa",
    "Harangalagama",
    "Harasbedda",
    "Hatton",
    "Hedunuwewa",
    "Hitigegama",
    "Kalaganwatta",
    "Kandapola",
    "Labukele",
    "Laxapana",
    "Lindula",
    "Madulla",
    "Maldeniya",
    "Maskeliya",
    "Maswela",
    "Padiyapelella",
    "Paradeka",
    "Pasbage",
    "Pattitalawa",
    "Peradeniya",
    "Pilawala",
    "Pilimatalawa",
    "Poholiyadda",
    "Polgolla",
    "Pujapitiya",
    "Pupuressa",
    "Pussellawa",
    "Putuhapuwa",
    "Rajawella",
    "Rambukpitiya",
    "Rambukwella",
    "Rangala",
    "Rantembe",
    "Rathukohodigala",
    "Rikillagaskada",
    "Sangarajapura",
    "Senarathwela",
    "Talatuoya",
    "Tawalantenna",
    "Teldeniya",
    "Tennekumbura",
    "Uda Peradeniya",
    "Udahentenna",
    "Udahingulwala",
    "Udatalawinna",
    "Udawatta",
    "Udispattuwa",
    "Ududumbara",
    "Uduwa",
    "Uduwahinna",
    "Uduwela",
    "Ulapane",
    "Ulpothagama",
    "Unuwinna",
    "Velamboda",
    "Wattappola",
    "Wattegama",
    "Yahalatenna",
    "Yatihalagala",
    "Metihakka",
    "Opalgala",
    "Ovilikanda",
    "Palapathwela",
    "Pallepola",
    "Perakanatta",
    "Pubbiliya",
    "Ranamuregama",
    "Rattota",
    "Selagama",
    "Sigiriya",
    "Talagoda Junction",
    "Talakiriyagama",
    "Udasgiriya",
    "Udatenna",
    "Ukuwela",
    "Wahacotte",
    "Walawela",
    "Yatawatta",
    "Patana",
    "Pitawala",
    "Pundaluoya",
    "Ramboda",
    "Rozella",
    "Rupaha",
    "Ruwaneliya",
    "Santhipura",
    "Talawakele",
    "Teripeha",
    "Udamadura",
    "Udapussallawa",
    "Walapane",
    "Watagoda",
    "Watawala",
    "Agbopura",
    "Buckmigama",
    "Chinabay",
    "Dehiwatte",
    "Echchilampattai",
    "Galmetiyawa",
    "Gomarankadawala",
    "Kaddaiparichchan",
    "Kanniya",
    "Kantalai",
    "KantalaiSugarFactory",
    "Lankapatuna",
    "Mahadivulwewa",
    "Maharugiramam",
    "Mallikativu",
    "Mawadichenai",
    "Pankulam",
    "Rottawewa",
    "Sampaltivu",
    "Sampur",
    "Serunuwara",
    "Seruwila",
    "Sirajnagar",
    "Somapura",
    "Tampalakamam",
    "Tiriyayi",
    "Toppur",
    "Trincomalee",
    "Vellamanal",
    "Wanela",
    "Addalaichenai",
    "Akkaraipattu",
    "Ampara",
    "Bakmitiyawa",
    "Central Camp",
    "Dadayamtalawa",
    "Damana",
    "Damanewela",
    "Deegawapiya",
    "Dehiattakandiya",
    "Devalahinda",
    "Digamadulla",
    "Dorakumbura",
    "Galapitagala",
    "Gonagolla",
    "Hingurana",
    "Hulannuge",
    "Kalmunai",
    "Kannakipuram",
    "Labunoruwa",
    "lmkkamam",
    "Madawalalanda",
    "Mahanagapura",
    "Mahaoya",
    "Malwatta",
    "Mangalagama",
    "Marathamune",
    "Mawanagama",
    "Oluvil",
    "Padiyatalawa",
    "Pahalalanda",
    "Palamunai",
    "Panama",
    "Pannalagama",
    "Paragahakele",
    "Periyaneelavanai",
    "Polwaga Janapadaya",
    "Pottuvil",
    "Rajagalatenna",
    "Sainthamaruthu",
    "Samanthurai",
    "Serankada",
    "Siripura",
    "Siyambalawewa",
    "Tempitiya",
    "Thambiluvil",
    "Tirukovil",
    "Uhana",
    "Wadinagala",
    "Wanagamuwa",
    "Andiyagala",
    "Angamuwa",
    "Anuradhapura",
    "Awukana",
    "Bogahawewa",
    "Dematawewa",
    "Dunumadalawa",
    "Dutuwewa",
    "Elayapattuwa",
    "Eppawala",
    "Etawatunuwewa",
    "Etaweeragollewa",
    "Galadivulwewa",
    "Galenbindunuwewa",
    "Galkadawala",
    "Galkiriyagama",
    "Galkulama",
    "Galnewa",
    "Gambirigaswewa",
    "Ganewalpola",
    "Gemunupura",
    "Getalawa",
    "Gnanikulama",
    "Gonahaddenawa",
    "Habarana",
    "Halmillawa",
    "Halmillawetiya",
    "Hidogama",
    "Horawpatana",
    "Horiwila",
    "Hurigaswewa",
    "Hurulunikawewa",
    "Kagama",
    "Kahatagasdigiliya",
    "Kahatagollewa",
    "Kalakarambewa",
    "Kalankuttiya",
    "Kalaoya",
    "Kalawedi Ulpotha",
    "Kallanchiya",
    "Kapugallawa",
    "Karagahawewa",
    "Labunoruwa",
    "lhala Halmillewa",
    "lhalagama",
    "lpologama",
    "Madatugama",
    "Maha Elagamuwa",
    "Mahabulankulama",
    "Mahailluppallama",
    "Mahakanadarawa",
    "Mahapothana",
    "Mahasenpura",
    "Mahawilachchiya",
    "Mailagaswewa",
    "Malwanagama",
    "Maneruwa",
    "Maradankadawala",
    "Maradankalla",
    "Medawachchiya",
    "Megodawewa",
    "Padavi Maithripura",
    "Padavi arakramapura",
    "Padavi Sripura",
    "Padavi Sritissapura",
    "Padaviya",
    "Padikaramaduwa",
    "Pahala Halmillewa",
    "Pahala Maragahawe",
    "Pahalagama",
    "Palagala",
    "Palugaswewa",
    "Pandukabayapura",
    "Pandulagama",
    "Parakumpura",
    "Parangiyawadiya",
    "Parasangahawewa",
    "Pemaduwa",
    "Perimiyankulama",
    "Pihimbiyagolewa",
    "Pubbogama",
    "Pulmoddai",
    "Punewa",
    "Rajanganaya",
    "Rambewa",
    "Rampathwila",
    "Ranorawa",
    "Rathmalgahawewa",
    "Saliyapura",
    "Seeppukulama",
    "Senapura",
    "Sivalakulama",
    "Siyambalewa",
    "Sravasthipura",
    "Talawa",
    "Tambuttegama",
    "Tammennawa",
    "Tantirimale",
    "Telhiriyawa",
    "Tirappane",
    "Tittagonewa",
    "Udunuwara Colony",
    "Upuldeniya",
    "Uttimaduwa",
    "Viharapalugama",
    "Vijithapura",
    "Wahalkada",
    "Wahamalgollewa",
    "Walagambahuwa",
    "Walahaviddawewa",
    "Alutwewa",
    "Aralaganwila",
    "Aselapura",
    "Attanakadawala",
    "Bakamuna",
    "Dalukana",
    "Damminna",
    "Dewagala",
    "Dimbulagala",
    "Divulankadawala",
    "Divuldamana",
    "Diyabeduma",
    "Diyasenpura",
    "Elahera",
    "Ellewewa",
    "Galamuna",
    "Galoya Junction",
    "Giritale",
    "Hansayapalama",
    "Hingurakdamana",
    "Hingurakgoda",
    "Jayanthipura",
    "Jayasiripura",
    "Kalingaela",
    "Kalukele Badanagala",
    "Lakshauyana",
    "Maduruoya",
    "MahaAmbagaswewa",
    "Mahatalakolawewa",
    "Mahawela Sinhapura",
    "Mampitiya",
    "Medirigiriya",
    "Meegaswewa",
    "Onegama",
    "Palugasdamana",
    "Parakramasamudraya",
    "Pelatiyawa",
    "Pimburattewa",
    "Polonnaruwa",
    "Pulastigama",
    "Sevanapitiya",
    "Sinhagama",
    "Sungavila",
    "Talpotha",
    "Tamankaduwa",
    "Tambala",
    "Unagalavehera",
    "Yodaela",
    "Yudaganawa",
    "Alahengama",
    "Alahitiyawa",
    "Alawatuwala",
    "Alawwa",
    "Ambakote",
    "Ambanpola",
    "Anhandiya",
    "Anukkane",
    "Aragoda",
    "Ataragalla",
    "Awulegama",
    "Balalla",
    "Bamunukotuwa",
    "Bandara Koswatta",
    "Bingiriya",
    "Bogamulla",
    "Bopitiya",
    "Boraluwewa",
    "Boyagane",
    "Bujjomuwa",
    "Buluwala",
    "Dambadeniya",
    "Daraluwa",
    "Deegalla",
    "Delwite",
    "Demataluwa",
    "Diddeniya",
    "Digannewa",
    "Divullegoda",
    "Dodangaslanda",
    "Doratiyawa",
    "Dummalasuriya",
    "Ehetuwewa",
    "Elibichchiya",
    "Embogama",
    "Etungahakotuwa",
    "Galgamuwa",
    "Gallewa",
    "Girathalana",
    "Giriulla",
    "Gokaralla",
    "Gonawila",
    "Halmillawewa",
    "Hengamuwa",
    "Hettipola",
    "Hilogama",
    "Hindagolla",
    "Hiriyala Lenawa",
    "Hiruwalpola",
    "Horambawa",
    "Hulogedara",
    "Hulugalla",
    "Hunupola",
    "Ihala Gomugomuwa",
    "Ihala Katugampala",
    "Indulgodakanda",
    "Inguruwatta",
    "Iriyagolla",
    "Ithanawatta",
    "Kadigawa",
    "Kahapathwala",
    "Kalugamuwa",
    "Kanadeniyawala",
    "Kanattewewa",
    "Karagahagedara",
    "Karambe",
    "Labbala",
    "lbbagamuwa",
    "lhala Kadigamuwa",
    "llukhena",
    "Lonahettiya",
    "Madahapola",
    "Madakumburumulla",
    "Maduragoda",
    "Maeliya",
    "Magulagama",
    "Mahagalkadawala",
    "Mahagirilla",
    "Mahamukalanyaya",
    "Mahananneriya",
    "Maharachchimulla",
    "Maho",
    "Makulewa",
    "Makulpotha",
    "Makulwewa",
    "Malagane",
    "Mandapola",
    "Maspotha",
    "Mawathagama",
    "Medivawa",
    "Meegalawa",
    "Meetanwala",
    "Melsiripura",
    "Metikumbura",
    "Metiyagane",
    "Padeniya",
    "Padiwela",
    "Pahalagiribawa",
    "Pahamune",
    "Palukadawala",
    "Panadaragama",
    "Panagamuwa",
    "Panaliya",
    "Panliyadda",
    "Pannala",
    "Pansiyagama",
    "Periyakadneluwa",
    "Pihimbiya Ratmale",
    "Pihimbuwa",
    "Pilessa",
    "Polgahawela",
    "Polpitigama",
    "Pothuhera",
    "Puswelitenna",
    "Ridibendiella",
    "Ridigama",
    "Saliya Asokapura",
    "Sandalankawa",
    "Sirisetagama",
    "Siyambalangamuwa",
    "Solepura",
    "Solewewa",
    "Sunandapura",
    "Talawattegedara",
    "Tambutta",
    "Thalahitimulla",
    "Thalakolawewa",
    "Thalwita",
    "Thambagalla",
    "Tharana Udawela",
    "Thimbiriyawa",
    "Tisogama",
    "Torayaya",
    "Tuttiripitigama",
    "Udubaddawa",
    "Uhumiya",
    "Ulpotha Pallekele",
    "Wadakada",
    "Wadumunnegedara",
    "Walakumburumulla",
    "Wannigama",
    "Wannikudawewa",
    "Wannilhalagama",
    "Wannirasnayakapura",
    "Warawewa",
    "Wariyapola",
    "Watuwatta",
    "Weerapokuna",
    "Yakwila",
    "Yatigaloluwa",
    "Adippala" ,
    "Ambakandawila",
    "Anamaduwa",
    "Andigama",
    "Angunawila",
    "Attawilluwa",
    "Bangadeniya",
    "Baranankattuwa",
    "Battuluoya",
    "Bujjampola",
    "Chilaw",
    "Dankotuwa",
    "Dunkannawa",
    "Eluwankulama",
    "Ettale",
    "Galmuruwa",
    "Ihala Kottaramulla",
    "Ihala Puliyankulama",
    "Ilippadeniya",
    "Akarella",
    "Atakalanpanna",
    "Ayagama",
    "Balangoda",
    "Batatota",
    "Belihuloya",
    "Bolthumbe",
    "Bomluwageaina",
    "Bulutota",
    "Dambuluwana",
    "Daugala",
    "Dela",
    "Delwala",
    "Demuwatha",
    "Dodampe",
    "Doloswalakanda",
    "Dumbara Manana",
    "Eheliyagoda",
    "Elapatha",
    "Ellagawa",
    "Ellaulla",
    "Ellawala",
    "Embilipitiya",
    "Eratna",
    "Erepola",
    "Gabbela",
    "Gallella",
    "Gangeyaya",
    "Gawaragiriya",
    "Getahetta",
    "Gillimale",
    "Godagampola",
    "Alawatura",
    "Algama",
    "Alutnuwara",
    "Ambalakanda",
    "Ambulugala",
    "Amitirigala",
    "Ampagala",
    "Anhettigama",
    "Aranayaka",
    "Aruggammana",
    "Atale",
    "Batuwita",
    "Beligala(Sab)",
    "Berannawa",
    "Bopitiya",
    "Boralankada",
    "Bossella",
    "Bulathkohupitiya",
    "Damunupola",
    "Debathgama",
    "Dedugala",
    "Deewala Pallegama",
    "Dehiowita",
    "Deldeniya",
    "Deloluwa",
    "Deraniyagala",
    "Dewalegama",
    "Dewanagala",
    "Inginimitiya",
    "Ismailpuram",
    "Kakkapalliya",
    "Kalladiya",
    "Kalpitiya",
    "Kandakuliya",
    "Lihiriyagama",
    "Lunuwila",
    "Madampe",
    "Madurankuliya",
    "Mahakumbukkadawala",
    "Mahauswewa",
    "Mahawewa",
    "Mampuri",
    "Mangalaeliya",
    "Marawila",
    "Palaviya",
    "Palliwasalturai",
    "Panirendawa",
    "Godakawela",
    "Gurubewilagama",
    "Halpe",
    "Halwinna",
    "Handagiriya",
    "Hapugastenna",
    "Hatangala",
    "Hatarabage",
    "Hidellana",
    "Hiramadagama",
    "Ihalagama",
    "Ittakanda",
    "Kahangama",
    "Kahawatta",
    "Kalawana",
    "Kaltota",
    "Lellopitiya",
    "lmbulpe",
    "Madalagama",
    "Mahawalatenna",
    "Makandura Sabara",
    "Malwala Junction",
    "Marapana",
    "Matuwagalagama",
    "Medagalatur",
    "Meddekanda",
    "Omalpe",
    "Opanayaka",
    "Padalangala",
    "Pallebedda",
    "Pambagolla",
    "Panamura",
    "Dombemada",
    "Dorawaka",
    "Dunumala",
    "Galapitamada",
    "Galatara",
    "Galigamuwa Town",
    "Galpatha(Sab)",
    "Gantuna",
    "Gonagala",
    "Hakahinna",
    "Hakbellawaka",
    "Helamada",
    "Hemmatagama",
    "Hettimulla",
    "Hewadiwela",
    "Hingula",
    "Hinguralakanda",
    "Hiriwadunna",
    "Imbulana",
    "Imbulgasdeniya",
    "Kabagamuwa",
    "Kannattota",
    "Lewangama",
    "Mahabage",
    "Mahapallegama",
    "Maharangalla",
    "Makehelwala",
    "Malalpola",
    "Pothuwatawana",
    "Puttalam",
    "PuttalamCementFactory",
    "Rajakadaluwa",
    "Saliyawewa Junction",
    "Serukele",
    "Sirambiadiya",
    "Siyambalagashene",
    "Tabbowa",
    "Talawila Church",
    "Toduwawa",
    "Udappuwa",
    "Uridyawa",
    "Vanathawilluwa",
    "Waikkal",
    "Watugahamulla",
    "Yogiyana",
    "Panapitiya",
    "Panapola",
    "Panawala",
    "Parakaduwa",
    "Pebotuwa",
    "Pelmadulla",
    "Pimbura",
    "Pinnawala",
    "Pothupitiya",
    "Rajawaka",
    "Rakwana",
    "Ranwala",
    "Rassagala",
    "Ratna Hangamuwa",
    "Ratnapura",
    "Samanalawewa",
    "Sri Palabaddala",
    "Sudagala",
    "Talakolahinna",
    "Tanjantenna",
    "Teppanawa",
    "Tunkama",
    "Udaha Hawupe",
    "Udakarawita",
    "Udaniriella",
    "Udawalawe",
    "Ullinduwawa",
    "Veddagala",
    "Vijeriya",
    "Waleboda",
    "Watapotha",
    "Waturawa",
    "Maliboda",
    "Malmaduwa",
    "Mawanella",
    "Parape",
    "Pattampitiya",
    "Pitagaldeniya",
    "Pothukoladeniya",
    "Rambukkana",
    "Ruwanwella",
    "Seaforth Colony",
    "Talgaspitiya",
    "Teligama",
    "Tholangamuwa",
    "Thotawella",
    "Tulhiriya",
    "Tuntota",
    "Udagaldeniya",
    "Udapotha",
    "Udumulla",
    "Undugoda",
    "Ussapitiya",
    "Wahakula",
    "Waharaka",
    "Warakapola",
    "Watura",
    "Weeoya",
    "Yatagama",
    "Yatapana",
    "Yatiyantota",
    "Yattogoda",
    "Agaliya",
    "Ahangama",
    "Ahungalla",
    "Akmeemana",
    "Aluthwala",
    "Ambalangoda",
    "Ampegama",
    "Amugoda",
    "Anangoda",
    "Angulugaha",
    "Ankokkawala",
    "Baddegama",
    "Balapitiya",
    "Banagala",
    "Batapola",
    "Bentota",
    "Boossa",
    "Dikkumbura",
    "Dodanduwa",
    "Ella Tanabaddegama",
    "Elpitiya",
    "Ethkandura",
    "Galle",
    "Ganegoda",
    "Ginimellagaha",
    "Gintota",
    "Godahena",
    "Gonagalpura",
    "Akuressa",
    "Alapaladeniya",
    "Aparekka",
    "Athuraliya",
    "Bengamuwa",
    "Beralapanathara",
    "Bopagoda",
    "Dampahala",
    "Deegala Llenama",
    "Deiyandara",
    "Dellawa",
    "Denagama",
    "Denipitiya",
    "Deniyaya",
    "Derangala",
    "Devinuwara (Dondra)",
    "Dikwella",
    "Diyagaha",
    "Diyalape",
    "Gandara",
    "Godapitiya",
    "Ambalantota",
    "Angunakolapelessa",
    "Bandagiriya Colony",
    "Barawakumbuka",
    "Beliatta",
    "Beragama",
    "Beralihela",
    "Bowalagama",
    "Bundala",
    "Ellagala",
    "Gangulandeniya",
    "Getamanna",
    "Goda Koggalla",
    "GonagamuwaUduwila",
    "Gonnoruwa",
    "Hakuruwela",
    "Hambantota",
    "Horewelagoda",
    "Gonamulla",
    "Gonapinuwala",
    "Habaraduwa",
    "Haburugala",
    "Halvitigala",
    "Hawpe",
    "Hikkaduwa",
    "Hiniduma",
    "Hiyare",
    "Ihala Walpola",
    "Kahaduwa",
    "Kahawa",
    "Kananke Bazaar",
    "Karagoda",
    "lhalahewessa",
    "lmaduwa",
    "lnduruwa",
    "Magedara",
    "Magedara",
    "Malgalla Talangalla",
    "Mapalagama",
    "Mapalagama",
    "Mapalagama Central",
    "Mattaka",
    "Meda-Keembiya",
    "Meetiyagoda",
    "Opatha",
    "Panangala",
    "Gomilamawarala",
    "Hakmana",
    "Handugala",
    "Horapawita",
    "Kalubowitiyana",
    "Kamburugamuwa",
    "Kamburupitiya",
    "Karagoda",
    "Lankagama",
    "Makandura",
    "Maliduwa",
    "Maramba",
    "Matara",
    "Mediripitiya",
    "Miella",
    "Pahala Millawa",
    "Palatuwa",
    "Paragala",
    "Parapamulla",
    "Pasgoda",
    "Penetiyana",
    "Hungama",
    "Ihala Beligala",
    "Ittademaliya",
    "Julampitiya",
    "Kahandamodara",
    "Lunama",
    "Lunugamwehera",
    "Magama",
    "Mahagalwewa",
    "Mamadala",
    "Medamulana",
    "Middeniya",
    "Migahajandur",
    "Padawkema",
    "Pahala Andarawewa",
    "Pallekanda",
    "Rammalawarapitiya",
    "Ranakeliya",
    "Pannimulla anagoda",
    "ParanaThanaYamgoda",
    "Pitigala",
    "Poddala",
    "Porawagama",
    "Rantotuwila",
    "Ratgama",
    "Talagampola",
    "Talgaspe",
    "Talgaswela",
    "Talpe",
    "Tawalama",
    "Tiranagama",
    "Udalamatta",
    "Udugama",
    "Uluvitike",
    "Unawatuna",
    "Unenwitiya",
    "Uragaha",
    "Uragasmanhandiya",
    "Wakwella",
    "Walahanduwa",
    "Wanchawela",
    "Wanduramba",
    "Warukandeniya",
    "Watugedara",
    "Yakkalamulla",
    "Yatalamatta",
    "Pitabeddara",
    "Pothdeniya",
    "Puhulwella",
    "Radawela",
    "Ransegoda",
    "Ratmale",
    "Rotumba",
    "Siyambalagoda",
    "Sultanagoda",
    "Telijjawila",
    "Thihagoda",
    "Urubokka",
    "Urugamuwa",
    "Urumutta",
    "Viharahena",
    "Walakanda",
    "Walasgala",
    "Waralla",
    "Yatiyana",
    "Ranmuduwewa",
    "Ranna",
    "Ratmalwala",
    "RU/Ridiyagama",
    "Sooriyawewa Town",
    "Tangalla",
    "Tissamaharama",
    "Uda Gomadiya",
    "Udamattala",
    "Uswewa",
    "Vitharandeniya",
    "Walasmulla",
    "Weeraketiya",
    "Weerawila",
    "Weerawila New Town",
    "Yatigala",
    "Akkarasiy",
    "Aluketiyaw",
    "Aluttaramma",
    "Ambadandegama",
    "Ambagahawatta",
    "Ambagasdowa",
    "Amunumulla",
    "Arawa",
    "Arawakumbura",
    "Arawatta",
    "Atakiriya",
    "Badulla",
    "Baduluoya",
    "Ballaketuwa",
    "Bambarapana",
    "Bandarawela",
    "Beramada",
    "Bibilegama",
    "Bogahakumbura",
    "Boragas",
    "Boralanda",
    "Bowela",
    "Dambana",
    "Demodara",
    "Diganatenna",
    "Dikkapitiya",
    "Dimbulana",
    "Divulapelessa",
    "Diyatalawa",
    "Dulgolla",
    "Egodawela",
    "Ella",
    "Ettampitiya",
    "Galauda",
    "Galedanda",
    "Galporuyaya",
    "Gamewela",
    "Gawarawela",
    "Girandurukotte",
    "Godunna",
    "Gurutalawa",
    "Haldummulla",
    "Angunakolawewa",
    "Ayiwela",
    "Badalkumbura",
    "Baduluwela",
    "Bakinigahawela",
    "Balaharuwa",
    "Bibile",
    "Buddama",
    "Buttala",
    "Dambagalla",
    "Diyakobala",
    "Dombagahawela",
    "Ekamutugama",
    "Ekiriyankumbura",
    "Ethimalewewa",
    "Hali Ela",
    "Hangunnawa",
    "Haputale",
    "Hebarawa",
    "Heeloya",
    "Helahalpe",
    "Helapupula",
    "Hewanakumbura",
    "Hingurukaduwa",
    "Hopton",
    "Idalgashinna",
    "Jangulla",
    "Kahataruppa",
    "Kalubululanda",
    "Kalugahakandura",
    "Kalupahana",
    "Kandaketya",
    "Kandegedara",
    "Kandepuhulpola",
    "Landewela",
    "Liyangahawela",
    "Lunugala",
    "Lunuwatta",
    "Madulsima",
    "Madulsima",
    "Mahiyanganaya",
    "Makulella",
    "Malgoda",
    "Maliyadda",
    "Mapakadawewa",
    "Maspanna",
    "Maussagolla",
    "Medawela Udukinda",
    "Medawelagama",
    "Meegahakiula",
    "Metigahatenna",
    "Ohiya",
    "Pahalarathkinda",
    "Pallekiruwa",
    "Passara",
    "Pathanewatta",
    "Pattiyagedara",
    "Ettiliwewa",
    "Galabedda",
    "Hambegamuwa",
    "Hulandawa",
    "Inginiyagala",
    "Kandaudapanguwa",
    "Kandawinna",
    "Mahagama Colony",
    "Marawa",
    "Mariarawa",
    "Medagana",
    "Obbegoda",
    "Okkampitiya",
    "Pangura",
    "Pitakumbura",
    "Pelagahatenna",
    "Perawella",
    "Pitamaruwa",
    "Pitapola",
    "Puhulpola",
    "Ratkarawwa",
    "Ridimaliyadda",
    "Rilpola",
    "Silmiyapura",
    "Sirimalgoda",
    "Sorabora Colony",
    "Soragune",
    "Soranatota",
    "Spring Valley",
    "Taldena",
    "Tennepanguwa",
    "Timbirigaspitiya",
    "Uduhawara",
    "Uraniya",
    "Uva Deegalla",
    "Uva Karandagolla",
    "Uva Mawelagama",
    "Uva Tenna",
    "Uva Tissapura",
    "Uva Uduwara",
    "Uvaparanagama",
    "Yalagamuwa",
    "Yalwela",
    "Randeniya",
    "Ruwalwela",
    "Sella Kataragama",
    "Sewanagala",
    "Siyambalagune",
    "Siyambalanduwa",
    "Suriara",
    "Tanamalwila",
    "Uva Gangodagama",
    "Uva Kudaoya",
    "Uva Pelawatta",
    "Warunagama",
    "Wedikumbura",
    "Jaffna",
    "Mannar",
    "Mullativu",
    "Vavuniya",
    "Kiribathgoda",
    "Kelaniya",
    "Kiribathgoda"];         
    

  constructor(
    private translocoService: TranslocoService,
    private registerpageService : RegisterpageService,
    private snackBar: MatSnackBar
  ) {}
  // @HostListener("document:mousemove", ["$event"])
  // onMouseMove(e) {
  //   var squares1 = document.getElementById("square1");
  //   var squares2 = document.getElementById("square2");
  //   var squares3 = document.getElementById("square3");
  //   var squares4 = document.getElementById("square4");
  //   var squares5 = document.getElementById("square5");
  //   var squares6 = document.getElementById("square6");
  //   var squares7 = document.getElementById("square7");
  //   var squares8 = document.getElementById("square8");

  //   var posX = e.clientX - window.innerWidth / 2;
  //   var posY = e.clientY - window.innerWidth / 6;

  //   squares1.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares2.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares3.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares4.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares5.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares6.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.05 +
  //     "deg) rotateX(" +
  //     posY * -0.05 +
  //     "deg)";
  //   squares7.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.02 +
  //     "deg) rotateX(" +
  //     posY * -0.02 +
  //     "deg)";
  //   squares8.style.transform =
  //     "perspective(500px) rotateY(" +
  //     posX * 0.02 +
  //     "deg) rotateX(" +
  //     posY * -0.02 +
  //     "deg)";
  // }


  ngOnInit() {
    this.initForm();
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");

    // this.onMouseMove(event);
    
  }

  initForm(){
    this.registrationForm = new FormGroup({
      'agentOutletName': new FormControl(null, Validators.required),
      'city': new FormControl(null, Validators.required),
      'firstName': new FormControl(null, [Validators.required, nameValidator]),
      'lastName': new FormControl(null, [Validators.required, nameValidator]),
      'mobileNo': new FormControl(null, [Validators.required, contactNo]),
      'otp': new FormControl(null, [Validators.required, otp])
    });
  }

  onVerify(){
    this.registrationForm.get("mobileNo").markAsTouched();
    if(this.registrationForm.get("mobileNo").valid){
      this.otp = true;
      let mobileNo:string = this.registrationForm.get("mobileNo").value;
      let formattedMobileNo = mobileNo.substring(1);
      this.registerpageService.sendOtp(formattedMobileNo).subscribe((response: any) => {
        setTimeout(() => { this.loading = false; }, 1500);
        if(response['code'] == 200){
          this.snackBar.open('Please enter OTP received via SMS !', 'Close', {
            horizontalPosition: "right",
            verticalPosition: "bottom",
            duration: 3 * 1000
          });
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: "Registration Completed Successfully !"
          // })
          this.registrationForm.get("otp").reset();
        }else if(response['code'] == 402){
          Swal.fire({
            icon: 'warning',
            title: response['message'],
            // text: "Registration Completed Successfully !"
          })
          this.registrationForm.get("otp").reset();
        } else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Something is wrong, Please try again !"
          })
        }
      });
    }
  }

  resendOtp(){
    this.resendOtpLoading = true;
    this.registrationForm.get("mobileNo").markAsTouched();
    if(this.registrationForm.get("mobileNo").valid){
      let mobileNo:string = this.registrationForm.get("mobileNo").value;
      let formattedMobileNo = mobileNo.substring(1);
      this.registerpageService.resendOtp(formattedMobileNo).subscribe((response: any) => {
        setTimeout(() => { this.resendOtpLoading = false; }, 1500);
        if(response['code'] == 200){
          this.snackBar.open('OTP resended successsfully !', 'Close', {
            horizontalPosition: "right",
            verticalPosition: "bottom",
            duration: 3 * 1000
          });
          this.registrationForm.get("otp").reset();
        }else if(response['code'] == 402){
          Swal.fire({
            icon: 'warning',
            title: response['message'],
            // text: "Registration Completed Successfully !"
          })
          this.registrationForm.get("otp").reset();
        } else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Something is wrong, Please try again !"
          })
        }
      });
    }

  }

  onLanguageChange(){
    if(this.selectedLanguage != null){
      this.translocoService.setActiveLang(this.selectedLanguage);
    }
  }

  onSubmit(){
    this.loading = true;
    this.registrationForm.markAllAsTouched();
    if(this.registrationForm.valid){
      
      let mobileNo:string = this.registrationForm.get("mobileNo").value;
      let formattedMobileNo = mobileNo.substring(1);

      let data = {
        "outletName" : this.registrationForm.get("agentOutletName")?.value,
        "city" : this.registrationForm.get("city")?.value,
        "firstName" : this.registrationForm.get("firstName")?.value,
        "lastName" : this.registrationForm.get("lastName")?.value,
        "mobileNo" : formattedMobileNo,
        "otp" : this.registrationForm.get("otp")?.value
      }
      this.registerpageService.register(data).subscribe((response: any) => {
        setTimeout(() => { this.loading = false; }, 1500);
        if(response['code'] == 200){
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: "Registration Completed Successfully !"
          })
          this.registrationForm.reset();
          this.otp = false;
        }else if(response['code'] == 402){
          Swal.fire({
            icon: 'warning',
            title: response['message'],
            // text: "Registration Completed Successfully !"
          })
          this.registrationForm.get("otp").reset();
        } else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Something is wrong, Please try again in a while !"
          })
        }
      });
    }else{
      this.loading = false;
    }
  }

  isFieldValid(field: string) {
    return !this.registrationForm.get(field)?.valid && this.registrationForm.get(field)?.touched;
  }

  displayFieldCss(field: string) {
    return {
      'is-invalid': this.isFieldValid(field)
    };
  }

  displayFieldCssSelect(field: string) {
    return {
      'is-invalid-select': this.isFieldValid(field)
    };
  }

  displayFieldCssPrepend(field: string) {
    return {
      'is-invalid-border': this.isFieldValid(field)
    };
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("register-page");
  }
}
