import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterpageService {

  // Create HTTP Request header
  httpOptions = {
    headers: new HttpHeaders({
        'Content-Type'                  : 'application/json',
        'Access-Control-Allow-Origin'   : '*'
    })
  };

  constructor(
    private httpClient          : HttpClient
  ) { }

  //api URL
  // apiUrl = '//localhost:8080/agent';
  apiUrl = '//164.90.216.82:8080/agent';

  // HTTP request error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
    } else {
        console.error(error);
    }
    return 'Something bad happened; please try again later';
  }

  register(data : any){
    return this.httpClient.post(this.apiUrl, data, this.httpOptions);
  }

  sendOtp(mobileNo: any){
    return this.httpClient.post(this.apiUrl +"/" + mobileNo, null, this.httpOptions);
  }

  resendOtp(mobileNo: any){
    return this.httpClient.post(this.apiUrl +"/resendOtp/" + mobileNo, null, this.httpOptions);
  }
}
