import { Validators } from "@angular/forms";

export const contactNo = Validators.pattern('^07[0-9]{8}$');
export const otp = Validators.pattern('^[0-9]{4}$');
export const nameValidator = Validators.pattern('^[a-zA-Z ]*[a-zA-Z]$');

export const contactNoLKR = Validators.pattern('^[7][0-9]{8}$');
export const lankanNicValidator = Validators.pattern('^([0-9]{9}[x|X|v|V]|[0-9]{12})$');
export const emailValidator = Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const fullNameValidator = Validators.pattern('^[A-Za-z. ]*$');
export const passportValidator = Validators.pattern('^[N][0-9]{7}');
export const drivingLicenValidator = Validators.pattern('^[A-Z0-9]{1,12}');

