import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

  // httpOptions = {
  //   headers: new HttpHeaders({
  //       'Content-Type'                  : 'application/json',
  //       'Access-Control-Allow-Origin'   : '*',
  //       'Authorization'                 : 'Bearer ' + this.localStorageService.getToken()
  //   })
    
  // };

  httpOptions={
    headers : new HttpHeaders({
      'Content-Type'                  : 'application/json',
      'Access-Control-Allow-Origin'   : '*',
    })
  }

  constructor(
    private router              : Router,
    private httpClient          : HttpClient

  ) { }

  baseUrl = 'https://mmblrewards.com:8443'
  apiUrl = 'https://mmblrewards.com:8443/beneficiary'
  apiUrl2 = 'https://mmblrewards.com:8443/security-question';

  
  // baseUrl  = 'http://159.65.138.148:8080'
  // apiUrl  = 'http://159.65.138.148:8080/beneficiary'
  // apiUrl2 = 'http://159.65.138.148:8080/security-question'
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
        console.error('An error occurred:', error.error.message);
    } else {
        console.error('Backend returned code error'+ error);
    }
    return 'Something bad happened; please try again later.';
}
  create(data){
    return this.httpClient.post(this.apiUrl,data,this.httpOptions);
  }
  otpverification(mobileNo,otp){
    return this.httpClient.put(this.apiUrl+'/'+mobileNo+'/'+otp,this.httpOptions);
  }


  accountCredential(data){
    return this.httpClient.put(this.apiUrl+'/register',data,this.httpOptions);
  }

  getSecurityQuestions(){
    return this.httpClient.get(this.apiUrl2 + '/A', this.httpOptions);
  }

  getPrivacyPolicyVersion(){
    return this.httpClient.get(this.baseUrl + '/privacy-policy', this.httpOptions);
  }

  validateId(data){
    return this.httpClient.post(this.apiUrl + '/validate-id',data,this.httpOptions);
  }
}
