import { drivingLicenValidator, emailValidator, otp } from './../registerpage/regex';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, MaxLengthValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { contactNoLKR,fullNameValidator, lankanNicValidator, passportValidator } from '../registerpage/regex';
import { ReferralService } from './referral.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  view                  : any = "screenRegister"; // screenRegister | screenOTP | screenVerified | screenAccountCredentials
  screenRegisterGroup   : FormGroup ;
  otpVerifivationForm   : FormGroup;
  accountCredentialForm : FormGroup;
  loading               : boolean;
  createLoading         : boolean = false;
  codeExists            = false;
  idExists              = false;
  emailExists           = false;
  mobileNumberExists    = false;
  activeModal           : any;
  idPlaceholder         : any = "NIC";
  referralCode          : any = null;
  beneficiaryId         : any = null;
  invalidPassword       : boolean = false;
  password              : any =null;
  confirmPassword       : any =null; 
  securityQuestionList  : any = [];
  mobileNumber          : any = null;
  star                  : any =null;
  substring             : any =null;
  idNumMaxLenght        : number = 12;
  email                 : any = null;
  tcAgree               : boolean = false;
  privacyPolicyData     : any = null;


  constructor(
    private referalservice : ReferralService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router
   ) { }

  ngOnInit(): void {
    this.initForm();
    this.getSecurityQuestions();
    this.getPrivacyPolicyVersion();
    // this.view = "screenAccountCredentials";

    this.route.queryParams.subscribe(params => {
      this.referralCode = params.code;
      if(this.referralCode == undefined){
        Swal.fire("", "No valid referral code found. Please visit with a valid link !", "warning");
      }
    })
  }

  getSecurityQuestions(){
    this.referalservice.getSecurityQuestions().subscribe((response) =>{
      setTimeout(()=> {},1500);
      this.securityQuestionList = response["responseData"];
    });
  }

  getPrivacyPolicyVersion(){
    this.referalservice.getPrivacyPolicyVersion().subscribe((response) =>{
      if(response["code"] == 200){
        this.privacyPolicyData = response["responseData"];
      }else {
        Swal.fire("", "Something went wrong. Please try again in a while !", "warning");
      }
      
    });
  }

  private initForm(){
    this.screenRegisterGroup = new FormGroup({
      "idtype"            : new FormControl("N",),
      'mobileNumber'      : new FormControl(null,[Validators.required,contactNoLKR]),
      'id'                : new FormControl(null,[Validators.required,lankanNicValidator]),
      'firstName'         : new FormControl(null,[Validators.required,fullNameValidator]),
      'lastName'          : new FormControl(null,[Validators.required,fullNameValidator]),
      'email'             : new FormControl(null,[emailValidator]),
    })

    this.otpVerifivationForm =new FormGroup({
      'otp' : new FormControl(null, [Validators.required, otp])
    })
    this.accountCredentialForm =new FormGroup({
        'securityQuestion'      :new FormControl(0,Validators.required),
        'seqAnswers'            :new FormControl(null,Validators.required),
        'userName'              :new FormControl(null,Validators.required),
        'password'              :new FormControl(null,Validators.required),
        'ConfirmPassword'       :new FormControl(null,Validators.required)
    })

  }

  changeView(view: any){    
    var userId = this.screenRegisterGroup.value.id;
    if(view == 'screenOTP'){
      this.accountCredentialForm.patchValue({
        'userName' :userId
      });
      this.create();
      

    }else if(view=='screenVerified'){
        this.otpverification();

    }else if(view == 'screenAccountCredentials'){

        
        this.view = "screenAccountCredentials";

     }else if(view ='download' ){
      // this.view ="download"
        this.accountCredential()

    }

  }

  onChangeIdType(event){
    this.idExists = false;
    let value = event.target.value;

    if(value == "N"){
      this.idPlaceholder = "NIC";
      this.idNumMaxLenght = 12;
      this.screenRegisterGroup.get("id").setValidators([Validators.required,lankanNicValidator]);
      this.screenRegisterGroup.get("id").updateValueAndValidity();
    }else if(value == "P"){
      this.idPlaceholder = "Passport No";
      this.idNumMaxLenght = 8;
      this.screenRegisterGroup.get("id").setValidators([Validators.required,passportValidator]);
      this.screenRegisterGroup.get("id").updateValueAndValidity();
    }else if(value == "D"){
      this.idNumMaxLenght = 12;
      this.idPlaceholder = "Driving License No";
      this.screenRegisterGroup.get("id").setValidators([Validators.required,drivingLicenValidator]);
      this.screenRegisterGroup.get("id").updateValueAndValidity();
    }
  }

  onAgree(event){
    this.tcAgree = event.target.checked;
  }

  goBack(view:any){
    if(view == 'screenRegister'){

      this.view ="screenRegister";
    }
    else{
      this.view="screenAccountCredentials";
    }
    // else if( view =='screenTop'){

    //   this.view ="screenTop"

    // }else if(view == 'screenVerified'){

    //   this.view ="screenVerified"
    // }

  }

  create(){
    if(this.screenRegisterGroup.valid && !this.idExists){
      this.email = this.screenRegisterGroup.value.email;
      if(this.email == ""){
        this.email = null
      }else{
        this.email 
      }
      let data={
        'mobileNo' :"0"+this.screenRegisterGroup.value.mobileNumber,
        'email'    :this.email,
        'idNumber' :this.screenRegisterGroup.value.id,
        'idType'   :this.screenRegisterGroup.value.idtype
      }

      if(!(this.idExists ||this.emailExists ||this.mobileNumberExists)){
        this.loading = true;
        this.referalservice.create(data).subscribe((response) =>{
          setTimeout(()=> {this.loading =false;},1500);
    
           if(response['code'] == '401'){//validation error
              response["validationErrors"].forEach( (currentValue, index) => {

                console.log("currentValue");
                console.log(currentValue);
                 if(currentValue.key == "mobileNo"){
                    this.mobileNumberExists = true;
                    
                }else if(currentValue.key =="email"){
                  this.emailExists =true;
             
                }else if(currentValue.key =="id"){
                  this.idExists =true;
                 
                }
                else{
                  Swal.fire(response["message"], currentValue.message, "warning");
                }
              });
    
            }else if(response['code'] == '416'){
              this.mobileNumberExists = true;
              this.loading = false;
              this.createLoading = false;
              console.log("this.mobileNumberExists");
              console.log(this.mobileNumberExists);
            }
            else if(response['code'] == '200'){
              this.beneficiaryId = response['beneficiaryId'];
              this.screenRegisterGroup.patchValue({
                'otp': null
              });
              this.view = "screenOTP";
              // this.typeSuccess();
              this.substring = this.screenRegisterGroup.value.mobileNumber.substring(6)
              this.star="******"
              this.mobileNumber = this.star+this.substring
            }else if(response['code'] != '200'){
              Swal.fire("", response["message"], "warning");
              
            }else{
              this.typeError();
            }
        })
      }
      
    }
  }
  
  otpverification(){
    

    if(this.otpVerifivationForm.valid){
      let otp = this.otpVerifivationForm.value.otp;
      let mobileNumber ="0"+this.screenRegisterGroup.value.mobileNumber;
      
      this.loading = true;
      this.referalservice.otpverification(mobileNumber,otp).subscribe((response) =>{
        setTimeout(()=> {this.loading =false;},1500);
  
         if(response['code'] == '401'){//validation error
            response["validationErrors"].forEach( (currentValue, index) => {
              if(currentValue.key == "code"){
                this.codeExists = true;
              }else{
                Swal.fire(response["message"], currentValue.message, "warning");
              }
            });
  
          }else if(response['code'] == '200'){
            
            this.view ="screenVerified";
            // this.typeSuccess();
            // this.createLoading = false;
            // this.changeView('default');
            
            // this.closeModal(this.activeModal);
          }else if(response['code'] != '200'){
            Swal.fire("", response["message"], "warning");
            
          }else{
            this.typeError();
          }
      })
    }
  }

  accountCredential(){
    if(this.accountCredentialForm.value.password == this.accountCredentialForm.value.ConfirmPassword){

      if(this.accountCredentialForm.valid){

        let privacyPolicyId = 0;
        if(this.privacyPolicyData != null){
          privacyPolicyId = this.privacyPolicyData.privacyPolicyId;
        }
      
        let data={
          "beneficiaryId"         :this.beneficiaryId,
          'idType'                :this.screenRegisterGroup.value.idtype,
          'idNumber'              :this.screenRegisterGroup.value.id,
          'firstName'             :this.screenRegisterGroup.value.firstName,
          'lastName'              :this.screenRegisterGroup.value.lastName,
          'email'                 :this.screenRegisterGroup.value.email,
          'mobileNo'              : '0'+this.screenRegisterGroup.value.mobileNumber,
          'securityQuestion'      :{ "securityQuestionId" : this.accountCredentialForm.value.securityQuestion },
          'securityAnswer'        :this.accountCredentialForm.value.seqAnswers,
          'username'              :this.screenRegisterGroup.value.id,
          'password'              :this.accountCredentialForm.value.password,
          'referralCode'          :this.referralCode,
          'privacyPolicyVersion'  : { "privacyPolicyId": privacyPolicyId }
        }
       
       
        if(this.accountCredentialForm.get("securityQuestion").value != 0){
          this.loading =true;

          this.referalservice.accountCredential(data).subscribe((response) =>{
            setTimeout(()=> {this.loading =false;},1500);
             if(response['code'] == '401'){//validation error
                response["validationErrors"].forEach( (currentValue, index) => {
                  if(currentValue.key == "code"){
                    this.codeExists = true;
                  }else{
                    Swal.fire(response["message"], currentValue.message, "warning");
                  }
                });
      
              }else if(response['code'] == '200'){
                // this.view = "download";
                this.router.navigateByUrl("download");
                Swal.fire("Success !", "Your account created successfully !", "success");
    
                // final links
                // this.typeSuccess();
                // this.createLoading = false;
                // this.changeView('default');
                
                this.closeModal(this.activeModal);
              }else if(response['code'] != '200'){
                Swal.fire("Create Failed", response["message"], "warning");
                
              }else{
                this.typeError();
              }
          })
        }
        
      }
    }else{
      // alert("Password is not match");
      // swal("Faild !", "Your Password is not matched!", "warning");
      // this.accountCredentialForm.reset()
      this.loading = false;
      this.invalidPassword = true;
      
    }
  }

  passwordMatch(){
    let password = this.accountCredentialForm.get("password");
    let confirmPassword = this.accountCredentialForm.get("ConfirmPassword");
    console.log("password");
    console.log(password);
    console.log("confirmPassword");
    console.log(confirmPassword);

    if(password.touched && confirmPassword.touched){
      if(password.value == confirmPassword.value){
        this.invalidPassword = false;
      }else{
        this.invalidPassword = true;
      }
    }else{
      this.invalidPassword = false;
    }
  }

  //close modal
  closeModal(modal) {
  this.modalService.dismissAll();
  
  // this.modalService.close(modal);
}

  // Warning Type Alert
  typeError(){
    Swal.fire("", "Something went wrong. Please try again in a while !", "warning")
  }
  //Alert End

  clear(){
    this.screenRegisterGroup.reset()
    this.screenRegisterGroup.patchValue({
      'idtype' : "N"

    });
    this.idPlaceholder = "NIC";
  }

  // onSubmit(){
  //   this.password =this.accountCredentialForm.value.password;
  //   this.confirmPassword = this.accountCredentialForm.value.ConfirmPassword;
 


  //    if(this.password != this.confirmPassword){
  //     alert("Password is miss match")
  //    }
  // }

  emailChange(event){
    this.emailExists = false;
  }

  idChange(){
    this.idExists = false;
  }

  mobileNUmberChange(){
    this.mobileNumberExists = false;
  }

  validateId(event){
    // console.log("event.target.value");
    // console.log(event.target.value);

    // console.log("this.screenRegisterGroup.value.id.valid");
    // console.log(this.screenRegisterGroup.get('id').valid);
  
        if(this.screenRegisterGroup.get('id').valid){
     
        let oldIdType = this.screenRegisterGroup.get("idtype").value;


        let data={
          'idNumber' :this.screenRegisterGroup.value.id,
          'idType'   :this.screenRegisterGroup.value.idtype
        }
        console.log("data in validate");
        console.log(data);
          this.referalservice.validateId(data).subscribe((response) =>{
            setTimeout(()=> {},1500);
    
            if(response['code'] == '401'){//validation error
             
               if(oldIdType == this.screenRegisterGroup.get("idtype").value){
                this.idExists =true;
               } 
              
                  
            }else if(response['code'] == '200'){
              this.idExists = false;
            }else{
              Swal.fire("", response["message"], "warning");
            }
    
            // console.log("response");
            // console.log(response);
          });
    }
  }

  onchangepassword(event){
    console.log("event.tatget.value");
    console.log(event.target.value);
    this.invalidPassword = false;
  }
}
