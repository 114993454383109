import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { IndexComponent } from "./pages/index/index.component";
import { ProfilepageComponent } from "./pages/examples/profilepage/profilepage.component";
import { RegisterpageComponent } from "./pages/examples/registerpage/registerpage.component";
import { LandingpageComponent } from "./pages/examples/landingpage/landingpage.component";
import { ReferralComponent } from "./pages/examples/referral/referral.component";
import { HomeComponent } from "./pages/examples/home/home.component";
import { DownloadComponent } from "./pages/examples/download/download.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "referral", component: ReferralComponent },
  { path: "home", component: HomeComponent },
  { path: "download", component: DownloadComponent },
  // { path: "profile", component: ProfilepageComponent },
  // { path: "register", component: RegisterpageComponent },
  // { path: "landing", component: LandingpageComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
